/*
---------------
1.6 Sessions
---------------*/

// .about-w {
//     background-image: url("/../../../assets/images/about.jpg");
// }
body,
.mat-card,
input,
.mat-form-field-label {
  font-family: "Roboto", Helvetica, sans-serif !important;
}

.form-bg {
  background-image: url("/../../../assets/img/OrangeGradeDashboardImg.png");
}

.form-bg {
  font-family: "Roboto", Helvetica, sans-serif !important;
  height: 100vh;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding: 0;
  border: 0;
  background-size: cover;
  background-position: top center;
  min-height: calc(100vh);
  height: auto;
}
.shared-icon {
  margin: 0.3rem;
  padding: 0.8rem 2rem;
  display: inline-block;
  color: #fff;
  border-radius: 5px;
}
.form-bg::before {
  // background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  content: "";
}

body .login-session {
  position: relative;
  z-index: 99;
  padding: 2rem !important;
}
.text-white {
  color: #fff;
}

.title-block {
  height: 75vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.hero {
  position: relative;
  .hero-overlay {
    background-color: rgba(60, 59, 63, 0.8);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
.forgot-img {
  box-shadow: 1.5px 2.6px 19px 0 rgba(0, 22, 17, 0.8);
}
.session {
  position: relative;
  z-index: 4000;
  min-height: calc(100vh + 100px);
  @include flexbox;
  @include flex-direction(column);
}
.error-text {
  font-size: 12rem;
  display: inline-block;
  color: #fff;
  line-height: normal;
}

.error-wrapper {
  text-align: center;
  z-index: 99;
  padding-top: 5rem;
  color: #fff;
}
.session-content {
  padding: 40px $gutter;
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  @include flex(1 0 auto);
  @include flex-direction(column);
  min-height: 100%;
}

.session-wrapper {
  @include flex(none);
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}

.lockscreen-avatar {
  position: relative;
  display: block;
  margin: -75px auto 0 !important;
}

.lockscreen-wrapper {
  @include flex(none);
  max-width: 280px;
  width: 100%;
  margin: 0 auto;
}

.error-title {
  font-size: 150px;
  line-height: 1.2;
  font-weight: 900;
  display: inline-table;
  position: relative;
  background: mat-color($primary);
  color: #fff;
  padding: 0 $gutter;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 0 $gutter;
  &:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-top-color: mat-color($primary);
    border-width: 8px;
    margin-left: -8px;
  }
}

.error-subtitle {
  font-size: 32px;
  font-weight: 300;
}

.notfound {
  background-image: url("/../../../assets/images/notfound.jpg");
}
