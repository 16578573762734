/*---------------
1.2 Typography
---------------*/

@font-face {
  font-family: "Avenir";
  src: url("/../../../assets/fonts/Avenir-Roman.woff2") format("woff2"),
    url("/../../../assets/fonts/Avenir-Roman.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir";
  src: url("/../../../assets/fonts/Avenir-Heavy.woff2") format("woff2"),
    url("/../../../assets/fonts/Avenir-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir";
  src: url("/../../../assets/fonts/Avenir-Oblique.woff2") format("woff2"),
    url("/../../../assets/fonts/Avenir-Oblique.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Avenir";
  src: url("/../../../assets/fonts/Avenir-HeavyOblique.woff2") format("woff2"),
    url("/../../../assets/fonts/Avenir-HeavyOblique.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

body,
.mat-card,
input,
.mat-form-field-label {
  font-family: "Roboto", Helvetica, sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Roboto", Helvetica, sans-serif !important;
  margin-bottom: $headings-margin-bottom;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1,
.h1 {
  font-size: $font-size-h1;
}
h2,
.h2 {
  font-size: $font-size-h2;
}
h3,
.h3 {
  font-size: $font-size-h3;
}
h4,
.h4 {
  font-size: $font-size-h4;
}
h5,
.h5 {
  font-size: $font-size-h5;
}
h6,
.h6 {
  font-size: $font-size-h6;
}

a {
  font-family: "Roboto", Helvetica, sans-serif !important;
  text-decoration: none;
  color: inherit;
  @include hover-focus {
    cursor: pointer;
    text-decoration: none;
    outline: 0;
  }
}

button {
  font-family: "Roboto", Helvetica, sans-serif !important;
  font-family: "Roboto", Helvetica, sans-serif !important;
  outline: 0;
}

figure {
  margin: 0;
}

hr {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid mat-color($foreground, divider);
}

strong,
b {
  font-weight: 700;
}
