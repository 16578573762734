@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $lengths in $spacers {
    $length-x:   map-get($lengths, x);
    $length-y:   map-get($lengths, y);

    .#{$abbrev}a-#{$size} { #{$prop}:        $length-y $length-x !important; } // a = All sides
    .#{$abbrev}t-#{$size} { #{$prop}-top:    $length-y !important; }
    .#{$abbrev}r-#{$size} { #{$prop}-right:  $length-x !important; }
    .#{$abbrev}b-#{$size} { #{$prop}-bottom: $length-y !important; }
    .#{$abbrev}l-#{$size} { #{$prop}-left:   $length-x !important; }

    // Axes
    .#{$abbrev}x-#{$size} {
      #{$prop}-right:  $length-x !important;
      #{$prop}-left:   $length-x !important;
    }
    .#{$abbrev}y-#{$size} {
      #{$prop}-top:    $length-y !important;
      #{$prop}-bottom: $length-y !important;
    }
  }
}

    //pad
    .p-1 {
        padding: 1rem;
    }
    
    .p-2 {
        padding: 2rem !important;
    }
    
    .p-3 {
        padding: 3rem !important;
    }