@import './modules/month/calendar-month-view';
@import './modules/week/calendar-week-view';
@import './modules/day/calendar-day-view';
@import './modules/common/calendar-tooltip';

@mixin cal-theme($overrides) {
  @include cal-month-view-theme($overrides);
  @include cal-week-view-theme($overrides);
  @include cal-tooltip-theme($overrides);
}
