/*
//--Theme Info--//

Theme Name: Optima Angular 5 Admin Template
Version: 1.0
Author: TrendSetter Themes


//-- Table of Content --//

---------------
1. Core
---------------
    1.1 Scaffolding
    1.2 Typography
    1.3 Main Sidebar
    1.4 Main Header
    1.5 Main Panel
    1.6 Sessions
    
---------------
2. Components
---------------
---------------
3. Material
---------------
    3.1 Material Variables
    3.2 Material Theme
    3.3 App Variables
    3.4 Mixin
    3.5 Material
    
---------------
3. Utilities
---------------*/
@import "scss/material.variables";
@import "scss/material.themes";
@import "scss/mixins/bi-app/bi-app-ltr";
// Core Variables and Mixins
@import "scss/app.variables";
@import "scss/mixins";
// Core Styles
@import "scss/core";
@import "scss/material";
@import "scss/utilities/utilities";

@media print {
  .horizontal-menu {
    display: none !important;
  }
  mat-toolbar {
    &.main-header {
      display: none;
    }
  }
  .body-container {
    overflow-x: hidden;
  }

  // @media only screen and (max-width: 900px) and (min-width: 700px){
  //     /* CSS that should be displayed if width is equal to or less than 991px and larger
  //      than 768px goes here */

  //    }

  .header,
  .page-sidebar,
  .quickview-wrapper,
  .overlay,
  .mat-toolbar-row,
  .mat-toolbar-single-row,
  .app-inner {
    display: none;
  }
  .page-container {
    @include padding-left(0);
  }
  .page-content-wrapper {
    .content {
      border-top: 0;
      .mat {
        border: none;
        .mat-body {
          padding: 0;
        }
      }
      .card {
        border: none;
        .card-body {
          padding: 0;
        }
      }
    }
  }

  [class^="padding-"],
  [class*="padding-"],
  .table tbody tr td {
    padding: 10px;
  }

  //    .app-inner, .horizontal-top-bar{
  //      display: none;
  //    }
  .pbi-container {
    margin-top: 5px;
    overflow-y: hidden;
  }

  @page {
    // size: 290mm 210mm;
    // margin: 10mm 0 5mm 0;
    overflow-y: hidden;
  }
}
#pbi-container > iframe {
  border: none !important;
  //  margin-left:  -13px;
  margin-top: -17px;
}

div.visualContainer.unselectable.readMode.hideBorder.noVisualTitle.visualHeaderAbove.droppableElement.ui-droppable.vcBody.themableBackgroundColor.themableBorderColorSolid {
  -webkit-print-color-adjust: exact;
}

.sophos-title {
  // background-color: red;
  // background-image: linear-gradient(to right, rgb(255, 81, 0), 15%,rgb(255, 153, 0));
  // color: white ;
  // min-height:40px ;
  text-transform: uppercase;
  // font-weight: 700;
  // padding-left:10px;
  // font-size:18px;
  // padding-top: 5px;
  background: linear-gradient(90deg, #e95f26 0%, #ff931e 100%);
  padding: 5px 5px 5px 15px;
  color: white;
  font-weight: bold;
  font-size: 20px;
}

.ngx-datatable.fixed-header
  .datatable-header
  .datatable-header-inner
  .datatable-header-cell {
  font-size: 15px !important;
  vertical-align: middle;
  font-weight: 700 !important;
  text-transform: uppercase;
}
.autocomplete-container {
  width: 330px;
}

.custom-modalbox {
  mat-dialog-container {
    padding: 0;
  }
}

// .marginclass1 {
//   margin-left: 20px;
//   overflow: auto;
//   height: 10px;
// }
.marginclass {
  margin-left: 20px;
}
.selectedcssclass {
  background-color: #e95f26;
}

// .example-container {
//   height: 100px;
//   overflow: auto;
// }
